import { getMenulists, getMenulist } from '@/api/menu'
import router from '@/router'

export default {
    namespaced: true, state: () => ({
        routers: [], // 动态路由（不包括本地路由，不能用于生成左侧菜单）
        menus: [], //  左侧菜单
    }), mutations: {
        setRouters(state, val) {
            state.routers = val
        }, setMenus(state, val) {
            state.menus = val
        },
    }, actions: {
        // 添加动态路由
        async routers(ctx) {
            const { data } = await getMenulist()

            console.log(data)

            data.forEach((item) => {
                // home 为布局文件
                // 分类 0菜单标题  1路由（二级菜单） 2权限按钮  3路由隐藏页面
                // 转成树形结构
                let whiteList = [1]
                if (whiteList.includes(item.grade)) {
                    let op = {
                        path: item.path, name: item.component, component: () => {
                            if (item.component.startsWith('User')) {
                                return import('@/views/user/' + item.component + '.vue')
                            } else if (item.component.startsWith('Sys')) {
                                return import('@/views/sys/' + item.component + '.vue')
                            } else if (item.component.startsWith('Art')) {
                                return import('@/views/art/' + item.component + '.vue')
                            } else if (item.component.startsWith('Test')) {
                                return import('@/views/test/' + item.component + '.vue')
                            } else if (item.component.startsWith('Order')) {
                                return import('@/views/order/' + item.component + '.vue')
                            } else if (item.component.startsWith('Api')) {
                                return import('@/views/api/' + item.component + '.vue')
                            } else if (item.component.startsWith('Goods')) {
                                return import('@/views/goods/' + item.component + '.vue')
                            } else if (item.component.startsWith('Agent')) {
                                return import('@/views/agent/' + item.component + '.vue')
                            } else if (item.component.startsWith('Log')) {
                                return import('@/views/log/' + item.component + '.vue')
                            } else if (item.component.startsWith('Mall')) {
                                return import('@/views/mall/' + item.component + '.vue')
                            } else if (item.component.startsWith('Activity')) {
                                return import('@/views/activity/' + item.component + '.vue')
                            } else if (item.component.startsWith('Business')) {
                                return import('@/views/business/' + item.component + '.vue')
                            } else if (item.component.startsWith('Finance')) {
                                return import('@/views/finance/' + item.component + '.vue')
                            }else {
                                return import('@/views/' + item.component + '.vue')
                            }
                        }, meta: {
                            title: item.name,
                        },
                    }
                    router.addRoute('home', op)
                }
            })
            // console.log(data)
            ctx.commit('setRouters', data)
        },

        // 形成左侧菜单结构
        menus(ctx) {
            const publicRuters = router.options.routes
            const data = ctx.state.routers
            let res = []
            let map = {}
            // console.log(data)
            data.forEach((item) => {
                if (item.grade != 2) {
                    item.children = []
                    map[item.id] = item
                    if (item.pid == 0) {
                        res.push(item)
                    } else {
                        map[item.pid].children.push(item)
                    }
                }
            })
            // console.log(res)
            const routerWhite = ['/', '/login', '/404', '/401', '/403']
            for (let i = 0; i < publicRuters.length; i++) {
                if (routerWhite.includes(publicRuters[i].path)) {
                    publicRuters.splice(i, 1)
                }
                if (publicRuters[i].path == '/login') {
                    publicRuters.splice(i, 1)
                }
            }
            ctx.commit('setMenus', [...publicRuters, ...res])
        },
    },
}
